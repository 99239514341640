import "./map.css";
import {Map, View} from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {fromLonLat} from "ol/proj";
import {XYZ} from "ol/source";
import {Hill, HillFile} from "./ts/Hill";
import {defaults as defaultControls, FullScreen, ScaleLine} from "ol/control";
import {GPXBuilder} from "./ts/GPXBuilder";
import {BaggingFile, Visit} from "./ts/Bag";
import {LayerDef} from "./ts/LayerDef";
import {MunroLayerDef} from "./ts/MunroLayerDef";
import {TopsLayerDef} from "./ts/TopsLayerDef";
import {CorbettsLayerDef} from "./ts/CorbettsLayerDef";
import {GrahamsLayerDef} from "./ts/GrahamsLayerDef";
import {MarilynLayerDef} from "./ts/MarilynLayerDef";
import {HumpsLayerDef} from "./ts/HumpsLayerDef";
import {SimmLayerDef} from "./ts/SimmLayerDef";
import {RemainingLayerDef} from "./ts/RemainingLayerDef";
import {loadStravaTraces} from "./ts/strava";

document.addEventListener("DOMContentLoaded", () => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop as string),
    });

    const rasterLayers: TileLayer<XYZ | OSM>[] = [];
    const localUrl = "http://localhost:8200/tile/{z}/{x}/{y}";
    const cycleUrl =
        "https://{a-c}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png";
    const zoomedInLayer = new TileLayer({
        minZoom: 13,
        source: new XYZ({
            url: params["localTiles"] ? localUrl : cycleUrl,
        }),
    });
    rasterLayers.push(zoomedInLayer);
    const osmLayer = new TileLayer({
        maxZoom: 13,
        source: new OSM(),
    });
    rasterLayers.push(osmLayer);
    rasterLayers.forEach((r) => r.setOpacity(0.6));

    const munroLayerDef = new MunroLayerDef();
    const layerDefs: LayerDef[] = [
        new RemainingLayerDef(),
        new HumpsLayerDef(),
        new MarilynLayerDef(),
        new SimmLayerDef(),
        new GrahamsLayerDef(),
        new CorbettsLayerDef(),
        new TopsLayerDef(),
        munroLayerDef,
    ];

    const stravaSource = new VectorSource({wrapX: false});
    const stravaLayer = new VectorLayer({source: stravaSource, visible: true});

    layerDefs.forEach((d) => {
        const source = new VectorSource({wrapX: false});
        d.setSource(source);
        const layer = new VectorLayer({source, visible: d.isVisible()});
        d.setLayer(layer);
    });

    const mapView = new View({maxZoom: 19});
    mapView.setZoom(10);
    mapView.setCenter(fromLonLat([-2.98, 54.4804]));

    function getPeopleList(peopleArray) {
        if (!peopleArray || !peopleArray.length) {
            return "On my own";
        }
        const maxIdx = peopleArray.length - 1;
        return (
            "With " +
            peopleArray.reduce((acc, cur, idx) => {
                if (idx === 0) {
                    return acc + " " + cur;
                }
                if (idx === maxIdx) {
                    return acc + " & " + cur;
                }
                return acc + ", " + cur;
            }, "")
        );
    }

    function initialiseMap() {
        const map = new Map({
            controls: defaultControls().extend([
                new FullScreen(),
                new ScaleLine(),
            ]),
            target: "map",
            layers: [
                ...rasterLayers,
                stravaLayer,
                ...layerDefs.map((d) => d.getLayer()),
            ],
            keyboardEventTarget: document,
            view: mapView,
        });

        map.on("click", function (e) {
            const feature = map.forEachFeatureAtPixel(e.pixel, (f) =>
                f.getProperties().hill ? f : false
            );
            if (!feature) {
                return;
            }

            document.getElementById("hill-details").style.display = "block";

            const properties = feature.getProperties();
            const hill = properties.hill as Hill;
            const visits = properties.visits as Visit[];

            document.getElementById("name").textContent = hill.name;
            document.getElementById("height").textContent = `${hill.elev}m`;
            document.getElementById("drop").textContent = `${hill.drop}m`;
            document.getElementById("id").textContent = String(hill.id);
            document.getElementById("visit-count").textContent =
                properties.visits.length;
            document.getElementById("categories").innerHTML = hill.categories
                .map(
                    (c) =>
                        `<div class="label label-${c.toLowerCase()}">${c}</div>`
                )
                .join("");

            loadStravaTraces(hill.id, visits, stravaSource);

            let visitDetails = document.getElementById("visit-details");
            visitDetails.innerHTML = "";
            visits.forEach((v: Visit) => {
                const visit = document.createElement("div");
                visit.classList.add("visit");

                const visitDate = document.createElement("div");
                const d = new Date(v.date);
                visitDate.textContent = d.toLocaleDateString(undefined, {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                });
                visit.append(visitDate);

                if (v.with?.length) {
                    const who = document.createElement("div");
                    who.textContent = getPeopleList(v.with);
                    visit.append(who);
                }

                const notes = document.createElement("div");
                notes.textContent = v.notes;
                visit.append(notes);

                if (v.strava) {
                    const stravaDiv = document.createElement("div");
                    const stravaLink = document.createElement("a");
                    stravaLink.href = `https://www.strava.com/activities/${v.strava}`;
                    stravaLink.textContent = "Strava";
                    stravaLink.target = "_blank";
                    stravaDiv.append(stravaLink);
                    visit.append(stravaDiv);
                }

                visitDetails.append(visit);
            });
            document.getElementById("visit-container").style.display =
                properties.visits.length ? "block" : "none";
        });
    }

    let gpxBuilder: GPXBuilder;

    function loadHillData(): Promise<Hill[]> {
        return fetch("hills-main.json")
            .then((r) => r.json())
            .then((hillFile: HillFile) => hillFile.hills);
    }

    function loadVisitData(): Promise<Visit[]> {
        return fetch("bagging.json")
            .then((r) => r.json())
            .then((bagging: BaggingFile) => bagging.bags);
    }

    function loadOtherHillData(): Promise<Hill[]> {
        const promises: Promise<Hill[]>[] = [];

        if (params["others"] || params["all"]) {
            promises.push(
                fetch("hills-secondary.json")
                    .then((r) => r.json())
                    .then((hillFile: HillFile) => hillFile.hills)
            );
        }
        if (params["all"]) {
            promises.push(
                fetch("hills-remaining.json")
                    .then((r) => r.json())
                    .then((hillFile: HillFile) => hillFile.hills)
            );
        }
        if (promises.length) {
            return Promise.all(promises).then((results) => {
                return results.flat(1);
            });
        }
        return Promise.resolve([]);
    }

    function loadHills() {
        Promise.all([loadHillData(), loadVisitData(), loadOtherHillData()])
            .then(([hills, visits, others]) => {
                layerDefs.forEach((def) => {
                    def.render(hills, visits, others);
                    def.setElementText();
                    def.setupGPX();
                });

                mapView.fit(munroLayerDef.getSource().getExtent());

                gpxBuilder = new GPXBuilder(hills, visits);

                layerDefs.forEach((def) => {
                    def.checkToggle();
                });
            })
            .catch((e) => {
                alert(e);
            });
    }

    initialiseMap();
    loadHills();

    layerDefs.forEach((def) => {
        def.setupToggleEvent();
    });

    document.getElementById("opacity").addEventListener("change", (event) => {
        const opacity = Number((event.currentTarget as any).value) / 100;
        rasterLayers.forEach((l) => l.setOpacity(opacity));
    });

    document.getElementById("gpx").addEventListener("click", () => {
        if (gpxBuilder) {
            const gpxString = gpxBuilder.build();
            const data = new Blob([gpxString], {type: "text/plain"});
            const url = window.URL.createObjectURL(data);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = "munros.gpx";
            document.body.appendChild(a);
            a.click();
        }
    });

    if (params["others"] || params["all"]) {
        document.getElementById("others_container").style.display = "block";
    }
    if (params["all"]) {
        document.getElementById("remaining_container").style.display = "block";
    }
});
