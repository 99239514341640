import {LayerDef} from "./LayerDef";
import {Hill} from "./Hill";
import {LayerDefBase} from "./LayerDefBase";
import {Style} from "ol/style";
import {COLOURS} from "./Colours";

export class TopsLayerDef extends LayerDefBase implements LayerDef {
    private visitedStyle = LayerDefBase.buildStyle(
        4,
        COLOURS.MUNRO.VISITED,
        "white",
        1
    );
    private unvisitedStyle = LayerDefBase.buildStyle(
        4,
        COLOURS.MUNRO.UNVISITED,
        "white",
        1
    );

    protected getToggleElem(): HTMLInputElement {
        return document.getElementById("tops_toggle") as HTMLInputElement;
    }

    isVisible(): boolean {
        return true;
    }

    setElementText() {
        const labelElem = document.getElementById(
            "tops_label"
        ) as HTMLLabelElement;
        this.setToggleLabelText(labelElem, "Munro Tops", this.getCounts());
    }

    protected getUnvisitedStyle(): Style {
        return this.unvisitedStyle;
    }

    protected getVisitedStyle(): Style {
        return this.visitedStyle;
    }

    protected getLayerHills(hills: Hill[], others: Hill[]): Hill[] {
        return hills.filter((h) => {
            return h.categories.includes("MUNRO_TOP");
        });
    }
}
