import {LayerDef} from "./LayerDef";
import {Hill} from "./Hill";
import {LayerDefBase} from "./LayerDefBase";
import {Fill, RegularShape, Stroke, Style} from "ol/style";
import {COLOURS} from "./Colours";
import {ValueTypes} from "ol/style/expressions";
import COLOR = ValueTypes.COLOR;

export class RemainingLayerDef extends LayerDefBase implements LayerDef {
    private visitedStyle = new Style({
        image: new RegularShape({
            fill: new Fill({color: COLOURS.HUMP.VISITED}),
            stroke: new Stroke({color: "white", width: 2}),
            points: 4,
            radius: 6,
        }),
    });

    private unvisitedStyle = new Style({
        image: new RegularShape({
            fill: new Fill({color: COLOURS.HUMP.UNVISITED}),
            stroke: new Stroke({color: "white", width: 2}),
            points: 4,
            radius: 6,
        }),
    });

    protected getToggleElem(): HTMLInputElement {
        return document.getElementById("remaining_toggle") as HTMLInputElement;
    }

    isVisible(): boolean {
        return true;
    }

    setElementText() {
        const labelElem = document.getElementById(
            "remaining_label"
        ) as HTMLLabelElement;
        this.setToggleLabelText(labelElem, "Other hills", this.getCounts());
    }

    protected getUnvisitedStyle(): Style {
        return this.unvisitedStyle;
    }

    protected getVisitedStyle(): Style {
        return this.visitedStyle;
    }

    protected getLayerHills(hills: Hill[], others: Hill[]): Hill[] {
        return others.filter((h) => !h.categories.length);
    }

    setupGPX() {
        const elem = document.getElementById("gpx_other");
        this.setGPXClickListener(elem, "others.gpx");
    }
}
