import {LayerDef} from "./LayerDef";
import {Hill} from "./Hill";
import {LayerDefBase} from "./LayerDefBase";
import {COLOURS} from "./Colours";
import {Style} from "ol/style";

export class HumpsLayerDef extends LayerDefBase implements LayerDef {
    private visitedStyle = LayerDefBase.buildStyle(5, COLOURS.HUMP.VISITED);
    private unvisitedStyle = LayerDefBase.buildStyle(5, COLOURS.HUMP.UNVISITED);

    protected getToggleElem(): HTMLInputElement {
        return document.getElementById("humps_toggle") as HTMLInputElement;
    }

    isVisible(): boolean {
        return false;
    }

    setElementText() {
        const labelElem = document.getElementById(
            "humps_label"
        ) as HTMLLabelElement;
        this.setToggleLabelText(labelElem, "Humps", this.getCounts());
    }

    protected getUnvisitedStyle(): Style {
        return this.unvisitedStyle;
    }

    protected getVisitedStyle(): Style {
        return this.visitedStyle;
    }

    protected getLayerHills(hills: Hill[], others: Hill[]): Hill[] {
        return others
            .filter((h) => h.categories.includes("HUMP"))
            .filter((h) => !h.categories.includes("MARILYN"));
    }

    setupGPX() {
        const elem = document.getElementById("gpx_hump");
        this.setGPXClickListener(elem, "humps.gpx");
    }
}
