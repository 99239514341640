import {LayerDef} from "./LayerDef";
import {Hill} from "./Hill";
import {LayerDefBase} from "./LayerDefBase";
import {Style} from "ol/style";
import {COLOURS} from "./Colours";

export class MunroLayerDef extends LayerDefBase implements LayerDef {
    private visitedStyle = LayerDefBase.buildStyle(7, COLOURS.MUNRO.VISITED);
    private unvisitedStyle = LayerDefBase.buildStyle(
        7,
        COLOURS.MUNRO.UNVISITED
    );

    protected getToggleElem(): HTMLInputElement {
        return undefined;
    }

    isVisible(): boolean {
        return true;
    }

    setElementText() {
        const munroCounts = this.getCounts();

        document.getElementById("count_done").textContent = String(
            munroCounts.done
        );
        document.getElementById("count_total").textContent = String(
            munroCounts.total
        );
        document.getElementById("percentage_done").textContent =
            ((munroCounts.done * 100) / munroCounts.total).toFixed(1) + "%";
    }

    protected getVisitedStyle(): Style {
        return this.visitedStyle;
    }

    protected getUnvisitedStyle(): Style {
        return this.unvisitedStyle;
    }

    protected getLayerHills(hills: Hill[], others: Hill[]): Hill[] {
        return hills.filter((h) => h.categories.includes("MUNRO"));
    }
}
