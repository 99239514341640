import {LayerDef} from "./LayerDef";
import {Hill} from "./Hill";
import {LayerDefBase} from "./LayerDefBase";
import {Style} from "ol/style";
import {COLOURS} from "./Colours";

export class SimmLayerDef extends LayerDefBase implements LayerDef {
    private visitedStyle = LayerDefBase.buildStyle(5, COLOURS.SIMM.VISITED);
    private unvisitedStyle = LayerDefBase.buildStyle(5, COLOURS.SIMM.UNVISITED);

    protected getToggleElem(): HTMLInputElement {
        return document.getElementById("simm_toggle") as HTMLInputElement;
    }

    isVisible(): boolean {
        return true;
    }

    setElementText() {
        const labelElem = document.getElementById(
            "simm_label"
        ) as HTMLLabelElement;
        this.setToggleLabelText(labelElem, "Simms", this.getCounts());
    }

    protected getUnvisitedStyle(): Style {
        return this.unvisitedStyle;
    }

    protected getVisitedStyle(): Style {
        return this.visitedStyle;
    }

    protected getLayerHills(hills: Hill[], others: Hill[]): Hill[] {
        return others
            .filter((h) => h.categories.includes("SIMM"))
            .filter((h) => !h.categories.includes("MARILYN"))
            .filter((h) => !h.categories.includes("HUMP"));
    }

    setupGPX() {
        const elem = document.getElementById("gpx_simm");
        this.setGPXClickListener(elem, "simms.gpx");
    }
}
